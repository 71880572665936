import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration
} from "@remix-run/react";
import { json } from "@remix-run/node";

// Load the GTM tracking id from the .env
export const loader = async () => {
  return json({});
};

export default function App() {
  return (
    <html>
      <head>
        <title>Theme Maestro</title>
        <meta charSet="utf-8" />

        <meta name="viewport" content="width=device-width,initial-scale=1" />

        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
